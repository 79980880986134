import { history } from '../'
import {
	disablePhase,
	getAllPhase,
	getAllPhaseByDepartmentAndStatus, getAllPhaseByFilter, getDashPhase, getDepartmentTicket, getPhasePaged,
	postPhase,
	postTicket,
	putCloseTicket, putPhase, putTicket,
	updateTicketPhase,
} from '../../api/ticket'

export const PHASE_LIST = 'PHASE_LIST'
export const FINALIZE_TICKET = 'FINALIZE_TICKET'
export const CREATE_PHASE = 'CREATE_PHASE'
export const DISABLE_PHASE = 'DISABLE_PHASE'
export const UPDATE_PHASE = 'UPDATE_PHASE'
export const UPDATE_PAGE_PHASE = 'UPDATE_PAGE_PHASE'
export const FILTER_PHASE = 'FILTER_PHASE'
export const UPDATE_TICKET = 'UPDATE_TICKET'


export function phaseList(id) {
	return (dispatch) => {
		getAllPhase(id).then(response => {
			dispatch({
				type: PHASE_LIST,
				list: response.data,
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function phaseByDepartmentAndStatus(department, status, search = '', enable = true) {
	return dispatch => {
		dispatch({
			type: PHASE_LIST,
			list: null
		})
		getAllPhaseByDepartmentAndStatus(department, status.reduce((prev, curr) => {
			if (!curr) {
				prev.push(...[1, 2])
			} else {
				prev.push(3)
			}
			return prev
		}, []), search, enable, 1).then(response => {
			getDepartmentTicket().then((responseCount) => {
				getDashPhase(department).then((responseDash) => {
					dispatch({
						type: PHASE_LIST,
						list: response.data,
						count: responseCount.data,
						dash: responseDash.data,
						department,
					})
				}).catch(error => {
					const status = error.response.status
					if (status === 401) {
						history.push('/login')
					}
				})

			}).catch(error => {
				const status = error.response.status
				if (status === 401) {
					history.push('/login')
				}
			})

		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}

export function phaseFilterByTypeAndStatus(filter) {
	return dispatch => {
		getAllPhaseByFilter(filter).then(response => {
			dispatch({
				type: FILTER_PHASE,
				data: response.data,
			})
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}
export function phasePaged(phase, status, search, enable, offset) {
	return (dispatch, getState) => {
		getPhasePaged(phase, status, search, enable, offset).then(response => {
			dispatch({
				type: UPDATE_PAGE_PHASE,
				id: phase,
				status,
				list: response.data
			})
		}).catch(error => {
			console.log(error)
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
		})
	}
}



export function finalizeTicket(id, phase) {
	return dispatch => {
		putCloseTicket(id)
			.then(() => {
				getAllPhase()
					.then(response => {
						dispatch({
							type: PHASE_LIST,
							list: response.data,
						})
					})
					.catch(error => console.error('getAllPhase: ', error))
			})
			.catch(err => console.error(err))
	}
}

export function createPhase(data, onSuccess, onError) {
	return (dispatch, getState) => {
		postPhase(data)
			.then(response => {
				/*if (Number(getState().ticket.department) === Number(data.department)) {
					dispatch({
						type: CREATE_PHASE,
						list: [{ ...response.data, active: true }],
						department: data.department,
					})
				}*/
				onSuccess && onSuccess()
			})
			.catch(error => {
				const status = error.response.status
				if (status === 401) {
					history.push('/login')
				}
				onError && onError()
			})
	}
}

export function updatePhase(data, onSuccess, onError) {
	return (dispatch, getState) => {
		putPhase(data.id, data)
			.then(response => {
				if (Number(getState().ticket.department) === Number(data.department)) {

					dispatch({
						type: UPDATE_PHASE,
						list: [{ ...response.data[0], active: true }],
						department: data.department,
					})
				}
				onSuccess && onSuccess()
			})
			.catch(error => {

				console.log(error)
				console.log(error.response)
				const status = error.response.status
				if (status === 401) {
					history.push('/login')
				}
				onError && onError()
			})
	}
}

export function createTicket(data, onSuccess, onError) {
	return dispatch => {
		postTicket(data).then(response => {
			/*dispatch({
				type: CREATE_PHASE,
				list: [{ ...response.data }]
			})*/
			onSuccess && onSuccess(response.data)
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}

			onError && onError()
		})
	}
}

export function moveTicket(data, onSuccess, onError) {
	return dispatch => {
		putTicket(data.id, {
			form: data.form,
			id_phase: data.id_phase,
		}).then(response => {

			onSuccess && onSuccess()
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}

			onError && onError()
		})
	}
}


export function phaseDisable(data, onSuccess, onError) {
	return dispatch => {
		disablePhase(data.id, { active: false }).then(response => {
			/*dispatch({
				type: DISABLE_PHASE,
				list: [data],
			})*/
			onSuccess && onSuccess()
		}).catch(error => {
			const status = error.response.status
			if (status === 401) {
				history.push('/login')
			}
			onError && onError()
		})
	}
}

export function updateTicket(idTicket, data) {
	return dispatch => {
		updateTicketPhase(idTicket, data)
			.then(() => {
				getAllPhase()
					.then(response => {
						dispatch({
							type: PHASE_LIST,
							list: response.data,
						})
					})
					.catch(error => console.error('getAllPhase: ', error))
			})
			.catch(error => console.error('updateTicketPhase: ', error))
	}
}
